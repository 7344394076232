import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"


export default class Help extends React.Component {
    render() {
        return (
           <Layout>
               <SEO title="News"/>
               <Typography component="h1">
                   Help
               </Typography>
               <br/><br/>
               <Typography variant="body2" component="p">
                   Here you can find information about using our tool with your favorite streaming software. Basically every software which
                   allows to add an overlay from a browser source will be supported.
                   <br/><br/>
                   Your streaming software are missing or need help? Just drop us a line!
               </Typography>
               <br/>

               <ExpansionPanel>
                   <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                   >
                       <Typography>OBS Studio</Typography>
                   </ExpansionPanelSummary>
                   <ExpansionPanelDetails>
                       <Typography variant="body2" component="p">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                           sit amet blandit leo lobortis eget.
                       </Typography>
                   </ExpansionPanelDetails>
               </ExpansionPanel>
               <ExpansionPanel>
                   <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                   >
                       <Typography>OBS Streamlabs</Typography>
                   </ExpansionPanelSummary>
                   <ExpansionPanelDetails>
                       <Typography variant="body2" component="p">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                           sit amet blandit leo lobortis eget.
                       </Typography>
                   </ExpansionPanelDetails>
               </ExpansionPanel>
               <ExpansionPanel>
                   <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                   >
                       <Typography>xStream</Typography>
                   </ExpansionPanelSummary>
                   <ExpansionPanelDetails>
                       <Typography variant="body2" component="p">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                           sit amet blandit leo lobortis eget.
                       </Typography>
                   </ExpansionPanelDetails>
               </ExpansionPanel>

           </Layout>
        )
    }
}